:root {
  --primary-color: #187989;
  --primary-color-4: #d5f4f6;
  --secondary-color-blue: #026a97;
  --secondary-color: #dd0050;
  --tertiary-color: #8d8d8d;
  --button-color: #024764;
  --light-gray: #c4c4c4;
  --background-color: #fafcfd;
  --input-background-color: rgba(2, 106, 151, 0.05);
  --border-color: #e9eaea;
  --container-color: #fff;
  --border-radius: 16px;
  --box-shadow: 4px 4px 48px rgba(0, 0, 0, 0.05);
}

body {
  margin: 0;
  background-color: var(--background-color);
  font-family: 'Manrope', 'Poppins', sans-serif, -apple-system,
    BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell',
    'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

#root {
  height: 100%;
}

.gmnoprint > div {
  box-shadow: none !important;
  left: -7rem !important;
  display: flex !important;
  width: auto !important;
  height: auto !important;
  background-color: transparent !important;
}

.gmnoprint > div > button {
  width: 55px !important;
  height: 55px !important;
  margin: 0 0.8em !important;
  border-radius: 50px !important;
  background-color: #fff !important;
}
.gmnoprint > div > button > img {
  height: 13px !important;
  width: 13px !important;
}
.gmnoprint > div > button + div {
  display: none !important;
}
code {
  font-family: source-code-pro, 'Manrope', Menlo, Monaco, Consolas,
    'Courier New', monospace;
}
.mm-logo-img {
  width: 220px !important;
  margin-left: 20px !important;
}
.ant-picker-cell-in-view.ant-picker-cell-today .ant-picker-cell-inner::before {
  border: transparent;
}

.ant-radio-button-wrapper:hover {
  color: var(--primary-color);
}
.ant-layout-sider {
  background-color: var(--container-color);
}

.ant-select {
  outline: none;
  border-radius: var(--border-radius);

  margin: 0.5rem;
  width: 100%;
}

.ant-select placeholder {
  color: #b0b0b0;
}

.ant-picker-cell-in-view.ant-picker-cell-selected .ant-picker-cell-inner {
  width: 30px;
  background-color: transparent;
  border-color: transparent;
}

.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
  color: var(--primary-color) !important;
  border: 1px solid var(--primary-color) !important;
}

.container {
  margin-right: auto;
  margin-left: auto;
}

.pac-container:after {
  background-image: none !important;
  height: 0px;
}

::-webkit-scrollbar {
  width: 20px;
}
::-webkit-scrollbar-track {
  background-color: transparent;
}
::-webkit-scrollbar-thumb {
  background-color: #d6dee1;
  border-radius: 20px;
  border: 6px solid transparent;
  background-clip: content-box;
}

.mm-assembly-modal > .ant-modal-content {
  box-shadow: none;
  border-radius: 16px;
  border: 1px solid #e9eaea;
}
.mm-assembly-modal > .ant-modal-content > .ant-modal-header {
  border-bottom: none;
  background-color: transparent;
}

.mm-assembly-modal > .ant-modal-content > .ant-modal-body {
  padding: 12px 20px;
}

@media (max-width: 530px) {
  .mm-assembly-modal {
    width: 93vw !important;
  }
}
